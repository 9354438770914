


























import Vue from "vue";
import Component from "vue-class-component";

import LayoutComponent from "@/components/Layout.vue";

import { initializeTheme } from "@/utils/ThemeUtil";
import { UiState } from "@/UiState";

@Component({
  components: {
    LayoutComponent,
  },
})
export default class PageNotFoundView extends Vue {
  uiState: UiState = UiState.getInstance();

  get errorDescription(): string {
    return this.$t("errors.pageNotFound.description") as string;
  }

  get errorContact(): string {
    return this.uiState.hasTheme
      ? (this.$t(
          "theme." + this.uiState.theme + ".errorContactEmail"
        ) as string)
      : (this.$t("themeDefaults.errorContactEmail") as string);
  }

  mounted(): void {
    this.uiState.isValid = false;
    initializeTheme(this);
  }
}
